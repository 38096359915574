import React from "react"
import Styles from "./contents.module.scss"
import Mainsection from "src/components/section/main"

const Contents = () => {
  const filetypes = [
    {
      title: "画像ファイル",
      body: "PNG, JPEG/JPG, GIF など",
      icon: "fas fa-image",
    },
    {
      title: "動画ファイル",
      body: "MP4, AVI, MPG, FLV, WMV など",
      icon: "fas fa-play",
    },
    {
      title: "音楽ファイル",
      body: "MP3 など",
      icon: "fas fa-guitar",
    },
    {
      title: "書類ファイル",
      body: "PDF など",
      icon: "fas fa-file",
    },
    {
      title: "ウェブアプリケーション",
      body: "Dockerイメージ",
      icon: "fab fa-docker",
    },
    {
      title: "外部リンク",
      body: "URL, URI など",
      icon: "fas fa-link",
    },
  ]
  return (
    <Mainsection title="様々なコンテンツをサクッとアップロード！">
      <div className="columns is-multiline is-variable is-4">
        {filetypes.map(type => (
          <div className={`column is-one-third ${Styles.content}`} key={type.title}>
            <div className={`box ${Styles.isVcentered} ${Styles.contentBody}`}>
              <div className="columns is-mobile is-vcentered" style={{ width: "100%" }}>
                <div className="column is-narrow has-text-primary" style={{ margin: "0 auto" }}>
                  <span className="icon is-medium"><i className={`${type.icon} fa-2x`} /></span>
                </div>
                <div className="column">
                  <p><strong>{type.title}</strong></p>
                  <p className={`is-size-7 ${Styles.exsamples}`}>{type.body}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Mainsection>
  )
}

export default Contents
