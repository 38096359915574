import React from "react"
import Styles from "./feature.module.scss"
import Subsection from "src/components/section/sub"
import Wrap from "src/components/wrap"
import TitleImg from "src/images/console_title@2x.png"
import Pickup1Img from "src/images/console_pickup_1@2x.png"
import Pickup2Img from "src/images/console_pickup_2@2x.png"
import Pickup3Img from "src/images/console_pickup_3@2x.png"

const FeatureTemp = ({ title, icon, img, children }) => {
  const image = (
    <figure className={`image ${Styles.isFullOnMobile}`}>
      <img src={img} alt={title} />
    </figure>
  )
  return (
    <Subsection title={title} icon={icon}>
      <div className={`columns ${Styles.feature}`}>
        <div className="column is-hidden-tablet has-text-centered">{image}</div>
        <div className={`column is-half ${Styles.featureText}`}>
          <div className="content">{children}</div>
        </div>
        <div className="column is-1 is-hidden-mobile"></div>
        <div className={`column is-hidden-mobile has-text-centered ${Styles.isVcentered}`}>{image}</div>
      </div>
    </Subsection>
  )
}

const Feature = () => (
  <section className="section">
    <div className="container">
      <div className={`box ${Styles.featureArea}`}>
        <div style={{position: "relative", bottom: "5.5rem", marginBottom: "-5.5rem"}}>
          <div className={`columns has-background-white is-centered is-vcentered ${Styles.title}`}>
            <div className="column is-3">
              <figure className="image is-hidden-tablet is-96x96" style={{position: "relative", top: "-5rem", margin: "0 auto -3rem"}}>
                <img src={TitleImg} alt="配信コンテンツの管理はとってもかんたん！" />
              </figure>
              <figure className="image is-hidden-mobile is-128x128" style={{position: "relative", top: "-2rem", margin: "0 auto"}}>
                <img src={TitleImg} alt="配信コンテンツの管理はとってもかんたん！" />
              </figure>
            </div>
            <div className="column" style={{maxWidth: "420px", margin: "-.5rem auto 0"}}>
              <div className="title has-text-primary is-size-5-mobile has-text-centered-mobile" style={{letterSpacing: ".075em", lineHeight: "1.5em"}}>
                <Wrap><span>配信コンテンツの</span><span>管理は</span><span>とっても</span><span>かんたん！</span></Wrap>
              </div>
            </div>
          </div>
        </div>
        <FeatureTemp title="スマホでもタブレットでも！" icon="fas fa-mobile-alt" img={Pickup1Img}>
          <p>管理コンソールへはウェブブラウザからアクセスするので、PCはもちろん、スマホやタブレットからも操作OK！</p>
          <p>店舗やイベント会場・屋外など、どこでも*その場で配信コンテンツのアップロード・更新が可能です。 </p>
        </FeatureTemp>

        <FeatureTemp title="直感的なUIでサクッと配信！" icon="fas fa-mobile" img={Pickup2Img}>
          <p>配信したいコンテンツを選択してタイトルなどを入力すれば、アップロード完了。すぐさまアクセス用QRコードが発行されます。</p>
          <p>コンテンツだけではなく配信ユニットについては、管理コンソールから24時間いつでも好きな時にお申し込み・設定変更できます。</p>
        </FeatureTemp>

        <FeatureTemp title="位置情報の登録でスポットをPR！" icon="fas fa-map-marked-alt" img={Pickup3Img}>
           <p>管理コンソールからnatadeCOCOスポットの位置情報を登録すると、スマホアプリの地図に表示され、スポットやコンテンツのプロモーションができます。</p>
        </FeatureTemp>

      </div>
    </div>
  </section>
)

export default Feature