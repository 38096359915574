import React from "react"
import Styles from "./section.module.scss"

const Subsection = ({ title, icon, children }) => (
  <div className="container">
    <div className="columns">
      <MobileMargin />
      <div className="column">
        <div className={`columns is-mobile is-vcentered ${Styles.subTitle}`}>
          {icon &&
            <div className="column is-narrow">
              <span className="icon is-hidden-tablet is-medium has-text-primary"><i className={`fa-2x ${icon}`} /></span>
              <span className="icon is-hidden-mobile is-large has-text-primary"><i className={`fa-3x ${icon}`} /></span>
            </div>
          }
          <div className="column has-text-weight-bold has-text-primary is-size-4 is-size-5-mobile">{title}</div>
        </div>
        {children}
      </div>
      <MobileMargin />
    </div>
  </div>
)

export default Subsection

const MobileMargin = () => <div className="column is-narrow is-hidden-mobile" style={{width: "2.5rem"}}></div>