import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Button from "src/components/LinkedButton"
import Hero from "src/components/console/hero"
import Contents from "src/components/console/contents"
import Feature from "src/components/console/feature"

const LeadingButton = () => (
  <section className="section">
    <div className="container has-text-centered">
      <Button to="https://console.natade-coco.com" title="さっそく使ってみる" />
    </div>
  </section>
)

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Hero />
      <Feature />
      <Contents />
      <LeadingButton />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
