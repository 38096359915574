import React from "react"
import Styles from "./hero.module.scss"
import Button from "src/components/LinkedButton"
import Title from "src/components/section/title"
import Copy from "src/components/section/salescopy"
import Wrap from "src/components/wrap"
import headerImage from "src/images/pattern_light.svg"
import heroDesktopImage from "src/images/console_hero@2x.png"
import heroMobileImage from "src/images/console_hero_mobile@2x.png"

const Hero = () => (
  <section className="hero" style={{backgroundImage: `url(${headerImage})`}}>
    <div className={`hero-body ${Styles.gradient}`}>
      <div className="container is-widescreen">
        <div className="columns is-vcentered">
          <div className="column is-half is-flex" style={{flexDirection: "column", justifyContent: "space-around"}}>
            <div className="content has-text-centered-mobile" style={{marginBottom: "3rem"}}>
              <RoundLabel> 誰でも簡単すぐ配信！</RoundLabel>
              <Title positionStyle="has-text-left">管理コンソール</Title>
              <div className="is-hidden-tablet" style={{ marginBottom: "2rem"}}>
                <img src={heroMobileImage} alt="モバイルの管理コンソール画面イメージ" style={{maxWidth: "200px"}} />
              </div>
              <Copy positionStyle="has-text-left">
                <Wrap>
                  <span>natadeCOCOスポットで</span><span>配信する</span><span>コンテンツを</span><span>簡単操作で</span><span>その場で</span><span>アップロード！</span><br/>
                  <span>あなたの</span><span>「今」伝えたい思いを</span><span>すぐに届けられます。</span>
                </Wrap>
              </Copy>
            </div>
            <div className="has-text-centered" style={{marginBottom: "3rem"}}>
              <Button title="さっそく使ってみる" to="https://console.natade-coco.com/" />
            </div>
          </div>
          <div className="column is-hidden-mobile">
            <div style={{marginLeft: "-48px"}}>
              <img src={heroDesktopImage} alt="モバイルとデスクトップの管理コンソール画面イメージ" style={{ position: "relative", left: "24px"}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Hero

const RoundLabel = ({children}) => <p className={`has-text-white has-text-weight-bold is-size-7-mobile ${Styles.subTitle}`} style={{marginBottom: "1.5em"}}>{children}</p>